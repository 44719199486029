<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row class="justify-content-end">
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>
        </b-card-header>

        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <a href="javascript:;" @click="openModal(data.item)" v-b-tooltip.hover.noninteractive="'Ubah Default Value'" class="list-icons-item" v-if="moduleRole('edit')">
                  <i class="icon-pencil5"></i>
                </a>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
    </div>

    <b-modal size="xl" id="modal_inform_consent" title="Form Inform Consent" hide-footer>
      <validation-observer ref="VFormEdit" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(doSubmitEdit)">
          <table class="table table-bordered table-sm table-input">
              <thead>
                  <tr>
                      <td colspan="3">
                          <div class="result_tab">
                              <h4>Nama Tindakan</h4>
                              <p>{{dataModal.auicm_name}}</p>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <th width="5%">No.</th>
                      <th width="45%">Jenis Informasi</th>
                      <th width="50%">Isi Informasi</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>1</td>
                      <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_diagnosis"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>Dasar Diagnosis</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_dasar_diagnosis"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>3</td>
                      <td>Tindakan Kedokteran</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_tindakan_dokter"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>4</td>
                      <td>Indikasi Tindakan</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_indikasi_tindakan"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>5</td>
                      <td>Tata Cara</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_tata_cara"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>6</td>
                      <td>Tujuan</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_tujuan"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>7</td>
                      <td>Risiko</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_risiko"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>8</td>
                      <td>Komplikasi</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_kompilasi"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>9</td>
                      <td>Prognosis</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_prognosis"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>10</td>
                      <td>Alternatif & Risiko</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_alternatif"></b-form-textarea>
                      </td>
                  </tr>
                  <tr>
                      <td>11</td>
                      <td>Lain-lain</td>
                      <td class="input-cell">
                        <b-form-textarea v-model="dataModal.auicm_lainnya"></b-form-textarea>
                      </td>
                  </tr>
              </tbody>
          </table>

          <div class="text-right mt-4">
            <b-button type="button" class="mr-2" variant="secondary" @click="closeModal">Tutup</b-button>
            <b-button type="button" variant="primary" @click="doSubmitEdit">Submit</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._

import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'auicm_id',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'auicm_name',
          label: 'Nama',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      dataModal: {}
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    openModal(item){
      this.$set(this, 'dataModal', item)
      this.$bvModal.show('modal_inform_consent')
    },
    closeModal(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('modal_inform_consent')
    },
    doSubmitEdit(){
      this.$refs.VFormEdit.validate().then(success => {
        if(!success) return 

        this.loadingOverlay = true

        Gen.apiRest("/do/" + this.modulePage, {
          data: {
            type: 'edit-data',
            ...this.dataModal
          }
        }).then(res => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal()
            this.apiGet()
          })
        }).catch(err => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Terjadi suatu kesalahan!'
          })
        })
      })
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>